import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue'),
  },
  {
    path: '/addresscreate',
    name: 'addresscreate',
    component: () => import('../views/AddressCreateView.vue'),
  },
  {
    path: '/addressedit',
    name: 'addressedit',
    component: () => import('../views/AddressEditView.vue'),
  },
  {
    path: '/audios',
    name: 'audios',
    component: () => import('../views/AudiosView.vue'),
  },
  {
    path: '/audiopacks',
    name: 'audiopacks',
    component: () => import('../views/AudioPacksView.vue'),
  },
  {
    path: '/audiopackcreate',
    name: 'audiopackcreate',
    component: () => import('../views/AudioPackCreateView.vue'),
  },
  {
    path: '/audiopackedit',
    name: 'audiopackedit',
    component: () => import('../views/AudioPackEditView.vue'),
  },
  {
    path: '/audiopacksend',
    name: 'audiopacksend',
    component: () => import('../views/AudioPackSendView.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/CalendarView.vue'),
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/EventsView.vue'),
  },
  {
    path: '/eventcreate',
    name: 'eventcreate',
    component: () => import('../views/EventCreateView.vue'),
  },
  {
    path: '/eventedit',
    name: 'eventedit',
    component: () => import('../views/EventEditView.vue'),
  },
  {
    path: '/externallinks',
    name: 'externallinks',
    component: () => import('../views/ExternalLinksView.vue'),
  },
  {
    path: '/externallinkcreate',
    name: 'externallinkcreate',
    component: () => import('../views/ExternalLinkCreateView.vue'),
  },
  {
    path: '/externallinkedit',
    name: 'externallinkedit',
    component: () => import('../views/ExternalLinkEditView.vue'),
  },
  {
    path: '/ibos',
    name: 'ibos',
    component: () => import('../views/IBOSView.vue'),
  },
  {
    path: '/ibocreate',
    name: 'ibocreate',
    component: () => import('../views/IBOCreateView.vue'),
  },
  {
    path: '/iboedit',
    name: 'iboedit',
    component: () => import('../views/IBOEditView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutView.vue'),
  },
  {
    path: '/player',
    name: 'player',
    component: () => import('../views/PlayerView.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/ProductsView.vue'),
  },
  {
    path: '/productedit',
    name: 'productedit',
    component: () => import('../views/ProductEditView.vue'),
  },
  {
    path: '/productcreate',
    name: 'productcreate',
    component: () => import('../views/ProductCreateView.vue'),
  },
  {
    path: '/productsofmonth',
    name: 'productsofmonth',
    component: () => import('../views/ProductsOfMonthView.vue'),
  },
  {
    path: '/productofmonthcreate',
    name: 'productofmonthcreat',
    component: () => import('../views/ProductOfMonthCreateView.vue'),
  },
  {
    path: '/productofmonthedit',
    name: 'productofmonthedit',
    component: () => import('../views/ProductOfMonthEditView.vue'),
  },
  {
    path: '/productpkgcreate',
    name: 'productpkgcreate',
    component: () => import('../views/ProductPackCreateView.vue'),
  },
  {
    path: '/productpkgedit',
    name: 'productpkgedit',
    component: () => import('../views/ProductPackEditView.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/ReportsView.vue'),
  },
  {
    path: '/reportsnotpaidorders',
    name: 'reportsnotpaidorders',
    component: () => import('../views/ReportsNotPaidOrdersView.vue'),
  },
  {
    path: '/reportslos',
    name: 'reportslos',
    component: () => import('../views/ReportsLOSView.vue'),
  },
  {
    path: '/reportseventsbydate',
    name: 'reportseventsbydate',
    component: () => import('../views/ReportsEventsByDateView.vue'),
  },
  {
    path: '/salesorders',
    name: 'salesorders',
    component: () => import('../views/SalesOrdersView.vue'),
  },
  {
    path: '/salesordersbyibo',
    name: 'salesordersbyibo',
    component: () => import('../views/SalesOrdersByIBOView.vue'),
  },
  {
    path: '/salesordersbyibogrouped',
    name: 'salesordersbyibogrouped',
    component: () => import('../views/SalesOrdersByIBOGroupedView.vue'),
  },
  {
    path: '/salesorder',
    name: 'salesorder',
    component: () => import('../views/SalesOrderView.vue'),
  },
  {
    path: '/salesorderdetail',
    name: 'salesorderdetail',
    component: () => import('../views/SalesOrderDetailView.vue'),
  },
  {
    path: '/salesorderaddress',
    name: 'salesorderaddress',
    component: () => import('../views/SalesOrderAddressView.vue'),
  },
  {
    path: '/salesordersummary',
    name: 'salesordersummary',
    component: () => import('../views/SalesOrderSummaryView.vue'),
  },
  {
    path: '/salesorderccpayment',
    name: 'salesorderccpayment',
    component: () => import('../views/SalesOrderCCPaymentView.vue'),
  },
  {
    path: '/salesorderpaymentresult',
    name: 'salesorderpaymentresult',
    component: () => import('../views/SalesOrderPaymentResultView.vue'),
  },
  {
    path: '/store',
    name: 'store',
    component: () => import('../views/StoreView.vue'),
  },
  {
    path: '/audiostransfer',
    name: 'audiostransfer',
    component: () => import('../views/AudiosTransferView.vue'),
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('../views/TicketsView.vue'),
  },
  {
    path: '/ticketssend',
    name: 'ticketssend',
    component: () => import('../views/TicketSendView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
